import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentsList() {
  // Use toast
  const toast = useToast()

  const refPaymentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'type', label: 'type', sortable: true },
    { key: 'category', sortable: false },
    { key: 'amount', label: 'amount', sortable: true },
    { key: 'payment', label: 'payment', sortable: true },
    { key: 'by', label: 'by', sortable: true },
    { key: '_paymentDate', label: 'payment Date', sortable: true },
    { key: 'status', label: 'status', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalPayments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = ['All', 10, 25, 50, 100]
  const searchQuery = ref('')
  const fromDate = ref('')
  const toDate = ref('')
  const sortBy = ref('_paymentDate')
  const isSortDirDesc = ref(true)
  const rangeFilter = ref(null)
  const statusFilter = ref(null)
  const totalPaymentAmount = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayments.value,
    }
  })

  const getApprovals = computed(() => {
    const response =  store.getters['account-payment/getApprovals']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      range: rangeFilter.value,
      status: statusFilter.value,
      from: fromDate.value,
      to: toDate.value,
    })

    const { approvals, atotal, atotalAmount } = response
    totalPayments.value = atotal
    totalPaymentAmount.value = atotalAmount

    return approvals
  })

  const refetchData = () => {
    refPaymentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, rangeFilter, statusFilter, fromDate, toDate], () => {
    refetchData()
  })

  const approvePayment = paymentEvent => {
    store.dispatch('account-payment/approvePayment', { payment: paymentEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const fetchPayments = (ctx, callback) => {
    store
      .dispatch('account-payment/fetchPayments')
      .then(() => {
        // const { Payments, total } = response.data

        // callback(Payments)
        
        // totalPayments.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Payments' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const approvePayments = paymentEvent => {
    store.dispatch('account-payment/approvePayments', { payment: paymentEvent }).then(response => {
      // eslint-disable-next-line no-use-before-define
      fetchPayments()

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      return response

    }).catch(error=> {
      toast({
          component: ToastificationContent,
          props: {
            title: 'Failed: '+error.response.data,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return error
    })
  }

  const genPaymentReport =  () => {
    //
    store.dispatch('account-payment/genPaymentReport', {
      q: searchQuery.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value,
      range: rangeFilter.value,
    }).then(() => {
      // eslint-disable-next-line no-use-before-define
      // fetchPayments()

      // toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: response.data,
      //     icon: 'CheckIcon',
      //     variant: 'success',
      //   },
      // })
    })
  }

  const resolvePaymentStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'approved') return 'success'
    if (status === 'cancelled') return 'danger'
    return 'secondary'
  }

  const resolveRangeVariant = type => {
    if (type === 'today') return 'Today'
    if (type === 'yesterday') return 'Yesterday'
    // if (type === 'this week') return 'This Week'
    // if (type === 'previous week') return 'Previous Week'
    if (type === 'this month') return 'This Month'
    if (type === 'previous month') return 'Previous Month'
    if (type === 'this year') return 'This Year'
    if (type === 'previous year') return 'Previous Year'
  }

  const resolvePaymentPaymentVariant =  payment => {
    if (payment === 'cash') return 'primary'
    if (payment === 'mobile_money') return 'warning'
    if (payment === 'bank') return 'info'
    return 'primary'
  }

  const resolvePaymentVariant = payment => {
    if (payment === 'cash') return 'Cash'
    if (payment === 'mobile_money') return 'Mobile Money'
    if (payment === 'bank') return 'Bank'
  }

  const resolveTypeVariant = type => {
    if (type === 'expense') return 'Expense'
    // if (type === 'water') return 'Water'
    // if (type === 'fuel') return 'Fuel'
    // if (type === 'cableTv') return 'CableTv'
    // if (type === 'gas') return 'Gas'
    // if (type === 'internet') return 'Internet'
    // if (type === 'trash') return 'Trash'
    // if (type === 'phone') return 'Phone'
    // if (type === 'welfare') return 'Welfare'
    // if (type === 'donations') return 'Donations'
    // if (type === 'salary') return 'Salary'
    // if (type === 'project') return 'Project'
    // if (type === 'miscellaneous') return 'Miscellaneous'
    // if (type === 'others') return 'Others'
  }

  const resolveTypeRoleIcon = role => {
    if (role === 'expense') return 'ActivityIcon'
    // if (role === 'fuel') return 'AnchorIcon'
    // if (role === 'cableTv') return 'TvIcon'
    // if (role === 'gas') return 'BoxIcon'
    // if (role === 'internet') return 'GlobeIcon'
    // if (role === 'trash') return 'Trash2Icon'
    // if (role === 'phone') return 'PhoneIcon'
    // if (role === 'welfare') return 'UsersIcon'
    // if (role === 'donations') return 'CreditCardIcon'
    // if (role === 'salary') return 'PocketIcon'
    // if (role === 'project') return 'FolderIcon'
    // if (role === 'miscellaneous') return 'FileIcon'
    // if (role === 'others') return 'PackageIcon'
    return ''
  }

  return {
    // onSubmit,
    getApprovals,
    fetchPayments,
    approvePayments,
    tableColumns,
    perPage,
    currentPage,
    totalPayments,
    dataMeta,
    perPageOptions,
    searchQuery,
    fromDate,
    toDate,
    sortBy,
    isSortDirDesc,
    refPaymentListTable,

    rangeFilter,
    statusFilter,
    resolveRangeVariant,
    resolvePaymentStatusVariant,
    refetchData,
    approvePayment,
    resolveTypeVariant,
    resolveTypeRoleIcon,
    totalPaymentAmount,
    resolvePaymentPaymentVariant,
    genPaymentReport,
    resolvePaymentVariant
  }
}
