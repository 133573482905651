<template>

    <!-- Table Container Card -->
    <b-card
      no-body
    >
  
      <div class="m-2">
  
        <!-- Table Top -->
        <b-row>
  
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'accounts-payment-list', params: {  add: true } }"
              class="mr-1"
            >
              Add
            </b-button>

            <b-button
              variant="outline-primary"
              class="mr-1"
              @click="genPaymentReport"
            >
              *.Excel
            </b-button>
            <b-button
                variant="outline-primary"
                :to="({ name: 'accounts-payment-preview', 
                    params: {
                        id: '0',
                        action: 'approvals',
                        params: {
                          sortBy: sortBy ? sortBy.value : 'id',
                          sortDesc: isSortDirDesc ? isSortDirDesc.value : false,
                          perPage: dataMeta ? dataMeta.of : 10,
                          page: 1,
                          q: searchQuery ? searchQuery : '', 
                          range: rangeFilter ? rangeFilter : null,
                          status: statusFilter ? statusFilter : null,
                        } 
                    } 
                  })"
              >
                <span class="text-nowrap"> Preview </span>
              </b-button>
          </b-col>
  
          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-datepicker
                  v-model="fromDate"
                  placeholder="Date From"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>
                
                <b-form-datepicker
                  v-model="toDate"
                  placeholder="Date To"
                  class="mr-1"
                  today-button
                  reset-button
                  close-button
                ></b-form-datepicker>
                
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <v-select
                v-model="rangeFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="rangeOptions"
                :reduce="range => range.value"
                class="invoice-filter-select mr-1"
                placeholder="Select Range"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
              <v-select
                v-model="statusFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="status => status.value"
                class="invoice-filter-select"
                placeholder="Select Status"
              >
                <template #selected-option="{ label }">
                  <span class="text-truncate overflow-hidden">
                    {{ label }}
                  </span>
                </template>
              </v-select>
            </div>
          </b-col>
        </b-row>
  
      </div>
  
      <b-table
        ref="refPaymentListTable"
        :items="getApprovals"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
  
        <!-- Column: Id -->
        <template #cell(id)="data">
          <!-- <b-link
            :to="{ name: 'apps-invoice-preview', params: { id: data.item.id }}"
            class="font-weight-bold"
          > -->
            <!-- #{{ data.value }} -->
            {{ data.index+1 }}
          <!-- </b-link> -->
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveTypeRoleIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-primary`"
            />
            <span class="font-weight-bold align-text-top text-capitalize">{{ resolveTypeVariant(data.item.type) }} </span>
          </div>

          </template>

          <template #cell(amount)="data">
            <span
                class="font-weight-bolder  text-primary"
            >
            {{ data.item.amount.toFixed(2) }}
           </span>
          </template>
          
          <!-- Column: Role -->
          <template #cell(payment)="data">
            <div class="text-nowrap">
              <!-- <feather-icon
                :icon="resolvePaymentRoleIcon(data.item.role)"
                size="18"
                class="mr-50"
                
              /> -->
              <b-badge :variant="`${resolvePaymentPaymentVariant(data.item.payment)}`">{{ resolvePaymentVariant(data.item.payment) }}</b-badge>
              <!-- <span class="align-text-top text-capitalize">{{ data.item.role }}</span> -->
            </div>
          </template>
  
          <!-- Column: DOB -->
          <template #cell(_paymentDate)="data">
            <div class="text-dark">
              {{   data.item._paymentDate }}
            </div>
          </template>

          <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              pill
              :variant="`light-${resolvePaymentStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>

        <template #cell(totalAmount)="data">
          <p
              class="font-weight-bolder text-right mb-0 text-primary"
            >
              {{ formatAmount(data.item.totalAmount) }}
          </p>
        </template>

        <template #cell(count)="data">
          <b-link
            class="font-weight-bold"
            :to="{ name: 'accounts-payment-list', params: {  type: data.item.type, date: new Date(data.item.paymentDate).toGMTString().substring(5,16), status: data.item.status, } }"
          >
            {{ data.item.count  }} payments
          </b-link>
        </template>
  
        <!-- Column: Actions -->
        <template #cell(actions)="data">
  
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.index+1}-send-icon`"
              icon="CheckIcon"
              class="cursor-pointer"
              size="16"
              @click="approvePayment(data.item)"
            />
            <b-tooltip
              title="Approve Payment"
              class="cursor-pointer"
              :target="`invoice-row-${data.index+1}-send-icon`"
            />
            <b-tooltip
              title="View Payments"
              class="cursor-pointer"
              :target="`invoice-row-${data.index+1}-preview-icon`"
            />
  
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class=""
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
            
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item @click="approvePayment(data.item)" v-if="data.item.status == 'pending'">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50">Approve</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="data.item.status == 'pending'" @click="cancelPayments(data.item)">
                <feather-icon icon="XIcon" />
                <span class="align-middle ml-50">Cancel</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
  
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
  
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ (perPage == 'All') ? getApprovals.length : dataMeta.from+' to '+dataMeta.to }} of {{ dataMeta.of }} entries </span>
            <span class="text-muted"> &#8212; {{ totalPaymentAmount.toLocaleString() }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
  
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPayments"
              :per-page="(perPage == 'All') ? getApprovals.length : perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
  
          </b-col>
  
        </b-row>
      </div>
    </b-card>
  
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BFormTextarea, BFormDatepicker, BFormTimepicker, BForm, BButton, BTable, BMedia, BAvatar, BLink, BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BAlert,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import { ref, onUnmounted, computed } from '@vue/composition-api'
  import store from '@/store'
  import usePaymentApproval from './usePaymentApproval'
  import flatPickr from 'vue-flatpickr-component'
  
  import paymentStoreModule from '../paymentStoreModule'
  import router from '@/router'
  import { formatAmount } from '@core/comp-functions/formatAmount'
  
  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormDatepicker,
      BFormTimepicker,
      BForm,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BFormGroup,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BTooltip,
      BAlert,
      flatPickr,
      vSelect,
    },
    data() {
      return {
        router
      }
    },
    setup() {
  
      const ACCOUNT_RECEIPT_STORE_MODULE_NAME = 'account-payment'
  
      // Register module
      if (!store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.registerModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME, paymentStoreModule)
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNT_RECEIPT_STORE_MODULE_NAME)
      })

      const statusOptions  = [
        { label: 'Approved', value: 'approved' },
        { label: 'Pending', value: 'pending' },
        { label: 'Cancelled', value: 'cancelled' },
      ]
  
      const rangeOptions = [
        { label: 'Today', value: 'today' },
        { label: 'Yesterday', value: 'yesterday' },
        // { label: 'This Week', value: 'this week' },
        // { label: 'Previous Week', value: 'previous week' },
        { label: 'This Month', value: 'this month' },
        { label: 'Previous Month', value: 'previous month' },
        { label: 'This Year', value: 'this year' },
        { label: 'Previous Year', value: 'previous year' }
      ]
  
      const {
        fetchPayments,
        approvePayment,
        getApprovals,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refPaymentListTable,
  
        rangeFilter,
        statusFilter,
  
        refetchData,
        resolvePaymentStatusVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        totalPaymentAmount,
        resolvePaymentPaymentVariant,
        genPaymentReport,
        resolvePaymentVariant
      } = usePaymentApproval()
  
      fetchPayments()
      
      
      return {
        approvePayment,
        getApprovals,
        tableColumns,
        perPage,
        currentPage,
        totalPayments,
        dataMeta,
        perPageOptions,
        searchQuery,
        fromDate,
        toDate,
        sortBy,
        isSortDirDesc,
        refPaymentListTable,
        statusOptions,
  
        rangeFilter,
        statusFilter,
        refetchData,
        rangeOptions,
        resolvePaymentStatusVariant,
        resolveTypeVariant,
        resolveTypeRoleIcon,
        totalPaymentAmount,
        resolvePaymentPaymentVariant,
        genPaymentReport,
        resolvePaymentVariant,
        formatAmount
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
  
  .invoice-filter-select {
    min-width: 190px;
  
    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }
  
    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  